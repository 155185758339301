import * as THREE from "three";

export const HexColors = {
  background: 0xdbe0ee,
  loading: 0xd2bdbe,
  detected: 0xf4c054,
  error: 0xfd542f,
};

export const ThreeColors = {
  // Source: Buck Brand Guidelines
  background: new THREE.Color(HexColors.background).convertSRGBToLinear(),
  loading: new THREE.Color(HexColors.loading).convertSRGBToLinear(),
  detected: new THREE.Color(HexColors.detected).convertSRGBToLinear(),
  error: new THREE.Color(HexColors.error).convertSRGBToLinear(),
};
