import React from "react";
import { useLoader } from "react-three-fiber";
import * as THREE from "three";

import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

export default function Glasses(props) {
  const fbx = useLoader(FBXLoader, "./glasses/gl_aviator.fbx");

  fbx.traverse((node) => {
    if (node instanceof THREE.Mesh || node instanceof THREE.Group) {
      // node.castShadow = true;
    } else {
      node.visible = false;
    }
  });

  return <primitive object={fbx} dispose={null} {...props} />;
}
