import React, { useCallback } from "react";
import { useLoader } from "react-three-fiber";
import * as THREE from "three";

import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

export default function Glasses(props) {
  const fbx = useLoader(FBXLoader, "./glasses/gl_pineapple.fbx");

  fbx.traverse((node) => {
    if (node instanceof THREE.Mesh || node instanceof THREE.Group) {
      if (node.name == "Circle012") node.visible = false;
      node.castShadow = true;
    } else {
      node.visible = false;
    }
  });

  return <primitive object={fbx} dispose={null} {...props} />;
}
