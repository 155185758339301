import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  Suspense,
  useMemo,
} from "react";
import { useThree, useFrame } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { useControl } from "react-three-gui";
// Assets //
import Gl_Aviator from "./assets/Glasses_Aviator";
import Gl_Pineapple from "./assets/Glasses_Pineapple";
import Hat_Tweed from "./assets/Hat_Tweed";
import Hat_Cartola from "./assets/Hat_Cartola";
import Hat_Sun from "./assets/Hat_Straw";
const Clamp = THREE.MathUtils.clamp;
const Lerp = THREE.MathUtils.lerp;

const SETTINGS = {
  POSE_LERP_SPEED: 15,
  TRACK_VALUES_MIN_THRESH: 0.1,
  MOUTH_OPEN_RANGE: 35,
};
// Face landmarks 68-points corresponding array indices
const FACE = {
  CHIN: 8,
  NOSE: 30,
  NOSE_TOP: 28,
  TEMPLE_L: 0,
  TEMPLE_R: 16,
  LIP_UPPER: 62,
  LIP_LOWER: 66,
  BROW_L: 19,
  BROW_R: 24,
  EYE_L: 41,
  EYE_R: 46,
};

export function Avatar({ poseTargets, loadedCallback }) {
  const state = useThree();
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const [glassesEnabled, setGlassesEnabled] = useState("none");
  const [hatEnabled, setHatEnabled] = useState("none");
  const group = useRef();
  const assetsGroup = useRef();
  const hatsGroup = useRef();
  const avatar = useRef();
  const raccoonMesh = useRef();

  const browYOffset = useRef(0);
  const bones = useRef({
    neck: null,
    head: null,
    headTip: null,
    jaw: null,
    L_brow: null,
    R_brow: null,
    nose: null,
    L_fringe: null,
    R_fringe: null,
    upperLip: null,
  });
  const expressions = useRef({
    happy: 0,
    sad: 0,
    angry: 0,
    disgusted: 0,
    fearful: 0,
    surprised: 0,
  });

  //#region Controllers
  const jawRotControl = useControl("jaw", {
    type: "number",
    min: 0,
    max: 1,
  });
  const l_brow_control = useControl("left brow", {
    type: "number",
    min: 0,
    max: 1,
    value: 0,
  });
  const r_brow_control = useControl("right brow", {
    type: "number",
    min: 0,
    max: 1,
    value: 0,
  });
  const glasses = useControl("glasses", {
    type: "select",
    items: ["none", "aviator", "pineapple"],
    value: "none",
    state: [glassesEnabled, setGlassesEnabled],
  });
  const hat = useControl("hat", {
    type: "select",
    items: ["none", "cartola", "tweed", "sun"],
    value: "none",
    state: [hatEnabled, setHatEnabled],
  });
  const faceColor = useControl("face color", {
    type: "color",
    value: "#F3B269",
    onChange: (c) => {
      if (raccoonMesh.current)
        raccoonMesh.current.material.color.copy(new THREE.Color(c));
    },
  });

  //#region Init
  const loader = useMemo((loader) => {
    return new GLTFLoader();
  }, []);

  useEffect(() => {
    state.camera.position.set(0, 0, 40);
  }, []);

  useEffect(() => {
    loader.load("Raccoon_v028.gltf", (loaded) => {
      initImportObj(loaded.scene.children[0]);
    });
  }, []);

  const initImportObj = useCallback((importObj) => {
    importObj.scale.setScalar(0.5);
    importObj.position.set(0, -12, 0);
    importObj.traverse((node) => {
      if (Object.keys(bones.current).includes(node.name))
        bones.current[node.name] = node;
      if (node instanceof THREE.Mesh) {
        node.castShadow = true;
        node.receiveShadow = true;
        node.material.metalness = 0;
      }
      if (node instanceof THREE.SkinnedMesh) {
        if (node.name == "raccoon") {
          raccoonMesh.current = node;
        }
      }
      //if (node instanceof THREE.Bone) console.log(node);
    });
    // console.log(importObj);
    group.current.add(importObj);
    avatar.current = importObj;
    bones.current.headTip.add(assetsGroup.current);
    assetsGroup.current.rotation.x = -Math.PI * 0.5;
    poseTargets = {};
    setAvatarLoaded(true);
    loadedCallback(true);
  });
  //#endregion Init

  useFrame((state, delta) => {
    if (avatarLoaded) {
      animateAvatar(delta);
    }
  });

  const animateAvatar = useCallback((delta) => {
    if (poseTargets.lerpOverride)
      SETTINGS.POSE_LERP_SPEED = poseTargets.lerpOverride;

    // Mix in slider controls
    let jawRotation = Lerp(
      -0.04,
      -Math.PI * 0.15,
      Clamp(poseTargets.jawRotation + jawRotControl, 0, 1)
    );
    const lEyebrowValue = Clamp(l_brow_control + poseTargets.lBrow, 0, 1);
    let lEyebrowTarget = Lerp(8.358 - 2, 8.358 + 4, lEyebrowValue);
    const rEyebrowValue = Clamp(r_brow_control + poseTargets.rBrow, 0, 1);
    let rEyebrowTarget = Lerp(8.358 - 2, 8.358 + 4, rEyebrowValue);

    // Lerp and apply to bones
    bones.current.jaw.rotation.x = Clamp(
      Lerp(
        bones.current.jaw.rotation.x,
        jawRotation,
        SETTINGS.POSE_LERP_SPEED * delta
      ),
      -Math.PI * 0.15,
      -0.04
    );
    bones.current.upperLip.position.y = Clamp(
      Lerp(
        bones.current.upperLip.position.y,
        Clamp(-9.3 - jawRotation * 3, -9.5, -8.3),
        SETTINGS.POSE_LERP_SPEED * delta
      ),
      -9.5,
      -8.3
    );
    bones.current.L_brow.position.y = Clamp(
      Lerp(
        bones.current.L_brow.position.y,
        rEyebrowTarget,
        SETTINGS.POSE_LERP_SPEED * delta
      ),
      6,
      13
    );
    bones.current.R_brow.position.y = Clamp(
      Lerp(
        bones.current.R_brow.position.y,
        lEyebrowTarget,
        SETTINGS.POSE_LERP_SPEED * delta
      ),
      6,
      13
    );
    bones.current.head.rotation.y = Clamp(
      Lerp(
        bones.current.head.rotation.y,
        poseTargets.rotationZ * 0.75,
        SETTINGS.POSE_LERP_SPEED * delta
      ),
      -Math.PI / 4,
      Math.PI / 4
    );
    bones.current.neck.rotation.y = Clamp(
      Lerp(
        bones.current.neck.rotation.y,
        poseTargets.rotationZ * 0.25,
        SETTINGS.POSE_LERP_SPEED * delta
      ),
      -Math.PI / 4,
      Math.PI / 4
    );
    bones.current.head.rotation.z = Clamp(
      Lerp(
        bones.current.head.rotation.z,
        -poseTargets.rotationY * 0.75,
        SETTINGS.POSE_LERP_SPEED * delta
      ),
      -Math.PI / 4,
      Math.PI / 4
    );
    bones.current.neck.rotation.z = Clamp(
      Lerp(
        bones.current.neck.rotation.z,
        -poseTargets.rotationY * 0.25,
        SETTINGS.POSE_LERP_SPEED * delta
      ),
      -Math.PI / 4,
      Math.PI / 4
    );

    /// Give the hats group some offsets
    browYOffset.current = Math.max(
      bones.current.L_brow.position.y - 8.358,
      bones.current.R_brow.position.y - 8.358
    );
    hatsGroup.current.position.y = browYOffset.current * 0.35;
    hatsGroup.current.rotation.x = browYOffset.current * -0.02;
  });

  return (
    <>
      <group name="AvatarGroup" ref={group}>
        <group name={"AssetsGroup"} ref={assetsGroup}>
          <group name={"HatsGroup"} ref={hatsGroup}>
            <Suspense fallback={null}>
              <Hat_Tweed
                visible={hatEnabled === "tweed"}
                scale={[3.78, 3.78, 3.78]}
                position={[0, -3.73, 0.5]}
                rotation={[0.04, 0.0, 0]}
              />
              <Hat_Cartola
                visible={hatEnabled === "cartola"}
                scale={[0.11, 0.11, 0.11]}
                position={[0, 7.5, 0.5]}
                rotation={[-0.1, 0, 0.06]}
              />
              <Hat_Sun
                visible={hatEnabled === "sun"}
                scale={[4.15, 4.15, 4.15]}
                position={[0, -14, -7]}
                rotation={[-0.3, 0, -0.04]}
              />
            </Suspense>
          </group>
          <Suspense fallback={null}>
            <Gl_Aviator
              visible={glassesEnabled === "aviator"}
              scale={[10, 10, 10]}
              position={[0, -13.5, 5]}
              rotation={[0, 0, 0]}
            />
            <Gl_Pineapple
              visible={glassesEnabled === "pineapple"}
              scale={[0.63, 0.63, 0.63]}
              position={[0, -10, 20]}
              rotation={[0, 0, 0]}
            />
          </Suspense>
        </group>
      </group>
    </>
  );
}

export default { Avatar };
