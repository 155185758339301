import React, { useRef } from "react";
import { useLoader } from "react-three-fiber";
import * as THREE from "three";

import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

export default function Hat(props) {
  const fbx = useLoader(FBXLoader, "./hats/strawhat.fbx");

  const mat = useRef(
    new THREE.MeshStandardMaterial({ color: 0xda70d6, roughness: 2 })
  );
  //console.log(fbx);
  fbx.traverse((node) => {
    if (node instanceof THREE.Mesh || node instanceof THREE.Group) {
      if (node instanceof THREE.Mesh) node.material = mat.current;
    } else {
      node.visible = false;
    }
  });

  return <primitive object={fbx} dispose={null} {...props} />;
}
