/* This component implements face-api.js - http://https://github.com/justadudewhohacks/face-api.js/  */

import React, { useState, useEffect, useRef, useCallback } from "react";
import * as faceapi from "../libs/face-api/face-api.js";
import "regenerator-runtime/runtime";
import * as THREE from "three";
const Clamp = THREE.MathUtils.clamp;

const SETTINGS = {
  TRACK_VALUES_MIN_THRESH: 0.1,
  MOUTH_OPEN_RANGE: 35,
};
// Face landmarks 68-points corresponding array indices
const FACE = {
  CHIN: 8,
  NOSE: 30,
  NOSE_TOP: 28,
  TEMPLE_L: 0,
  TEMPLE_R: 16,
  LIP_UPPER: 62,
  LIP_LOWER: 66,
  BROW_L: 19,
  BROW_R: 24,
  EYE_L: 41,
  EYE_R: 46,
};

export function DetectFace({
  poseTargets,
  settings,
  mode,
  minSampleInterval = 100,
  showHud = false,
}) {
  const [media, setMedia] = useState(null);
  const [hudText, setHudText] = useState();
  const [loaded, setLoaded] = useState(false);
  const [sampleInterval, setSampleInterval] = useState(minSampleInterval);
  const mouthOpenValuesBuffer = useRef([0, 0, 0, 0]);
  const eTime = useRef();
  const id = useRef(0);

  // Set new sample interval
  useEffect(() => {
    if (loaded && media != null && mode == "face-api")
      setNewSamplingInterval(sampleInterval);
    else {
      setHudText("");
      if (id.current) {
        clearInterval(id.current);
      }
    }
    return () => setHudText("");
  }, [media, loaded, mode, sampleInterval]);

  // Init only
  useEffect(() => {
    init();
  }, []);

  // Call to load
  const init = useCallback(() => {
    console.log("face-api loading...");

    const video = document.getElementById("video");
    const canRequestWebcam =
      navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    if (canRequestWebcam) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: settings.width,
            height: settings.height,
            frameRate: settings.frameRate,
            facingMode: "user",
          },
        })
        .then((stream) => {
          video.srcObject = stream;
          video.addEventListener("play", () => {
            setMedia(video);
          });
          video.play();
        })
        .catch((error) =>
          console.log("Unable to access the camera/webcam", error)
        );
    }

    console.log("loading face-api ml models...");
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri("./weights"),
      faceapi.nets.faceLandmark68Net.loadFromUri("./weights"),
      //faceapi.nets.faceRecognitionNet.loadFromUri("./weights"),
      //faceapi.nets.faceExpressionNet.loadFromUri("./weights"),
      // faceapi.nets.ssdMobilenetv1.loadFromUri("/weights"),
    ])
      .then(() => {
        setLoaded(true);
        console.log("face-api initializing...");
      })
      .catch(() => {
        console.log("loading ml models failed");
      });
  });

  // Request face samples at interval
  const setNewSamplingInterval = useCallback((interval) => {
    if (id.current) {
      clearInterval(id.current);
    }

    id.current = setInterval(async () => {
      eTime.current = Date.now();
      // Get sample
      const faceData = await faceapi
        .detectAllFaces(media, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks();
      // .withFaceDescriptors()  //
      // .withFaceExpressions(); // for expression detection

      if (faceData[0]) {
        setPoseTargets(faceData[0]);

        // Auto-adjust sample interval based on perf
        let queryTime = Date.now() - eTime.current;
        if (
          queryTime > minSampleInterval &&
          (queryTime < interval * 0.5 || queryTime > interval * 1.5)
        ) {
          setSampleInterval(
            Math.min(Math.max(queryTime, 1000 / settings.frameRate) * 1.5, 750)
          );
        }
        // Update hud
        if (showHud)
          setHudText("face-api tracking" + " | " + interval.toString() + "ms");
      } else if (showHud) setHudText("no face detected");
    }, sampleInterval);
  });

  // Calculate values sent to Avatar
  const setPoseTargets = useCallback((faceData) => {
    const landmarks = faceData.landmarks.positions;

    // Face - estimated Y rotation (radians)
    const inferredHeadRotationY =
      -(
        (landmarks[FACE.NOSE].x -
          landmarks[FACE.TEMPLE_L].x -
          (landmarks[FACE.TEMPLE_R].x - landmarks[FACE.NOSE].x)) *
        0.3 *
        Math.PI
      ) / 180;

    // Face - Z rotation approximation "tilt"
    const deltaX = landmarks[FACE.CHIN].x - landmarks[FACE.NOSE].x;
    const deltaY = landmarks[FACE.CHIN].y - landmarks[FACE.NOSE].y;
    const inferredHeadRotationZ =
      Math.atan2(deltaX, deltaY) - inferredHeadRotationY * 0.25;

    // Face - normalized screen X position
    //const faceXNormalized = landmarks[FACE.NOSE].x / settings.width; // - inferredHeadRotationY * 0.1;
    //const faceYNormalized = landmarks[FACE.NOSE].y / settings.height; // - inferredHeadRotationY * 0.1;

    // Face - mouth open estimation
    const lipsGap =
      landmarks[FACE.LIP_LOWER].y - landmarks[FACE.LIP_UPPER].y - 1;
    const mouthOpennessNorm =
      Clamp(lipsGap, 0, SETTINGS.MOUTH_OPEN_RANGE) / SETTINGS.MOUTH_OPEN_RANGE;
    const lastFrameDif = Math.abs(
      mouthOpennessNorm - mouthOpenValuesBuffer.current[3]
    );
    let mouthOpenAveraged = poseTargets.jawRotation;
    if (
      lastFrameDif > SETTINGS.TRACK_VALUES_MIN_THRESH ||
      mouthOpennessNorm < 0.1
    ) {
      mouthOpenValuesBuffer.current.pop();
      mouthOpenValuesBuffer.current.unshift(mouthOpennessNorm);
      for (let i = 0; i < mouthOpenValuesBuffer.current.length; i++) {
        mouthOpenAveraged += mouthOpenValuesBuffer.current[i];
      }
      mouthOpenAveraged /= 4;
    }
    poseTargets.jawRotation = mouthOpenAveraged;

    const nose = new THREE.Vector2(
      landmarks[FACE.NOSE].x,
      landmarks[FACE.NOSE].y
    );
    const lEye = new THREE.Vector2(
      landmarks[FACE.EYE_L].x,
      landmarks[FACE.EYE_L].y
    );
    const lBrow = new THREE.Vector2(
      landmarks[FACE.BROW_L].x,
      landmarks[FACE.BROW_L].y
    );
    const rEye = new THREE.Vector2(
      landmarks[FACE.EYE_R].x,
      landmarks[FACE.EYE_R].y
    );
    const rBrow = new THREE.Vector2(
      landmarks[FACE.BROW_R].x,
      landmarks[FACE.BROW_R].y
    );
    const lBrowDistance = lBrow.distanceTo(nose);
    const lEyeDistance = lEye.distanceTo(nose);
    const rBrowDistance = rBrow.distanceTo(nose);
    const rEyeDistance = rEye.distanceTo(nose);
    const lBrowRatio = lBrowDistance / lEyeDistance - 1;
    const lBrowNorm = (lBrowRatio - 0.3) / 0.3;
    const rBrowRatio = rBrowDistance / rEyeDistance - 1;
    const rBrowNorm = (rBrowRatio - 0.3) / 0.3;

    // Avatar - set pose targets
    poseTargets.rotationZ = -inferredHeadRotationZ;
    poseTargets.rotationY = inferredHeadRotationY;
    poseTargets.lBrow = lBrowNorm - Math.abs(inferredHeadRotationY * 1.25);
    poseTargets.rBrow = rBrowNorm - Math.abs(inferredHeadRotationY * 1.25);
    poseTargets.lerpOverride = Clamp(1000 / sampleInterval + 3, 10, 20);
  });

  return (
    <>
      {showHud && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            fontSize: "25px",
            padding: "10px 40px",
          }}
        >
          {hudText}
        </div>
      )}
    </>
  );
}
