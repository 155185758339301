import "../styles.css";
import React, { Suspense, useState, useRef, useEffect } from "react";
import { Canvas } from "react-three-fiber";
import { OrbitControls, Circle } from "drei";
import { Avatar } from "./Avatar";
import { Controls, useControl } from "react-three-gui";
import { DetectFace } from "./Detect_FaceApi";
import { DetectFaceBrfv5 } from "./Detect_BRFace";
import { ThreeColors } from "../colors";
import DebugInfo from "./utility/ClickObjectInfo";

const ENABLE_HUD = true;
const WEBCAM_SETTINGS = {
  width: 640,
  height: 480,
  frameRate: 30,
};
const DEFAULT_SDK = "face-api";

export const App = () => {
  const [faceAPIEnabled, setFaceAPIEnabled] = useState("disabled");
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const poseTargets = useRef({
    rotationZ: 0,
    rotationY: 0,
    jawRotation: 0,
    lBrow: 0,
    rBrow: 0,
  }); // Data container shared across components

  useEffect(() => {
    if (avatarLoaded) {
      console.log("Avatar Component Load Complete.");
      setFaceAPIEnabled(DEFAULT_SDK);
    }
  }, [avatarLoaded]);

  useControl("face sdk", {
    type: "select",
    items: ["disabled", "face-api", "brfv5"],
    value: DEFAULT_SDK,
    state: [faceAPIEnabled, setFaceAPIEnabled],
  });

  return (
    <>
      <Canvas
        concurrent
        colorManagement
        onCreated={({ gl }) => {
          gl.shadowMap.enabled = false;
        }}
      >
        <ambientLight intensity={0.2} color={"azure"} />
        <spotLight
          intensity={1}
          position={[-10, 15, 30]}
          color={0xfff2e8}
          penumbra={1}
        />
        {/* <DebugInfo /> */}
        <OrbitControls />
        <Circle args={[1000]} position={[0, 0, -9.25]} receiveShadow>
          <meshStandardMaterial
            attach="material"
            color={ThreeColors.background}
          ></meshStandardMaterial>
        </Circle>
        <Suspense fallback={null}>
          <Avatar
            poseTargets={poseTargets.current}
            loadedCallback={setAvatarLoaded}
          />
        </Suspense>
      </Canvas>
      <Controls />

      <DetectFace
        poseTargets={poseTargets.current}
        settings={WEBCAM_SETTINGS}
        mode={faceAPIEnabled}
        showHud={ENABLE_HUD}
        minSampleInterval="25"
      />

      <DetectFaceBrfv5
        poseTargets={poseTargets.current}
        settings={WEBCAM_SETTINGS}
        mode={faceAPIEnabled}
        showHud={ENABLE_HUD}
        minSampleInterval="25"
      />
    </>
  );
};

export default App;
