/* This component implements Beyond Reality Face SDK - https://github.com/Tastenkunst/brfv5-browser  */

import React, { useState, useEffect, useRef, useCallback } from "react";
import "regenerator-runtime/runtime";
import { brfv5 } from "../libs/brfv5/brfv5/brfv5__init.js";
import { loadBRFv5Model } from "../libs/brfv5/brfv5/brfv5__init.js";
import { configureCameraInput } from "../libs/brfv5/brfv5/brfv5__configure.js";
import { configureFaceTracking } from "../libs/brfv5/brfv5/brfv5__configure.js";
import { configureNumFacesToTrack } from "../libs/brfv5/brfv5/brfv5__configure.js";
import { startCamera } from "../libs/brfv5/utils/utils__camera.js";
import { drawInputMirrored } from "../libs/brfv5/utils/utils__canvas.js";
import * as THREE from "three";
const Clamp = THREE.MathUtils.clamp;

const SETTINGS = {
  POSE_LERP_SPEED: 10,
};
// Face landmarks 68-points corresponding array indices
const FACE = {
  CHIN: 8,
  NOSE: 30,
  NOSE_TOP: 28,
  TEMPLE_L: 0,
  TEMPLE_R: 16,
  LIP_UPPER: 62,
  LIP_LOWER: 66,
  BROW_L: 19,
  BROW_R: 24,
  EYE_L: 41,
  EYE_R: 46,
};

// TODO: refactor these brfv5 vars into component
const _appId = "brfv5.buck.webavatar"; // (mandatory): 8 to 64 characters, a-z . 0-9 allowed
let _brfv5Manager = null;
let _brfv5Config = null;
let _width = 0;
let _height = 0;
const _webcam = document.getElementById("video");
const _imageData = document.createElementNS(
  "http://www.w3.org/1999/xhtml",
  "canvas"
);

export function DetectFaceBrfv5({
  poseTargets,
  settings,
  mode,
  showHud = false,
}) {
  const [hudText, setHudText] = useState();
  const [loaded, setLoaded] = useState(false);
  const animID = useRef();

  // Set new sample interval
  useEffect(() => {
    console.log(mode);
    if (loaded && mode == "brfv5") requestAnimationFrame(trackFaces);
    else {
      cancelAnimationFrame(animID.current);
      setHudText("");
    }
    return () => setHudText("");
  }, [loaded, mode]);

  // Init only
  useEffect(() => {
    init();
  }, []);

  // Call to load
  const init = useCallback(() => {
    console.log("brfv5 loading...");

    startCamera(_webcam, {
      width: settings.width,
      height: settings.height,
      frameRate: settings.frameRate,
      facingMode: "user",
    })
      .then(({ video }) => {
        console.log(
          "openCamera: done: " + video.videoWidth + "x" + video.videoHeight
        );

        _width = video.videoWidth;
        _height = video.videoHeight;
        console.log(_imageData);
        _imageData.width = _width;
        _imageData.height = _height;

        configureTracking();
      })
      .catch((e) => {
        if (e) {
          console.error("Camera failed: ", e);
        }
      });

    loadBRFv5Model("68l", 8, "./models/", _appId, (progress) => {
      //console.log(progress);
    })
      .then(({ brfv5Manager, brfv5Config }) => {
        _brfv5Manager = brfv5Manager;
        _brfv5Config = brfv5Config;

        configureTracking();
      })
      .catch((e) => {
        console.error("BRFv5 failed: ", e);
      });
  });

  // Config BRFv5
  const configureTracking = useCallback(() => {
    if (_brfv5Config !== null && _width > 0) {
      configureCameraInput(_brfv5Config, _width, _height);
      configureNumFacesToTrack(_brfv5Config, 1);
      configureFaceTracking(_brfv5Config, 1, true); // default was 3

      //_brfv5Config.faceTrackingConfig.enableFreeRotation = false;
      //_brfv5Config.faceTrackingConfig.maxRotationZReset = 34.0;
      _brfv5Manager.configure(_brfv5Config);

      setLoaded(true);

      if (mode == "brfv5") trackFaces();
    }
  });

  // Get BRFv5 face data
  const trackFaces = useCallback(() => {
    if (!_brfv5Manager || !_brfv5Config || !_imageData) {
      return;
    }

    const ctx = _imageData.getContext("2d");

    drawInputMirrored(ctx, _width, _height, _webcam);

    _brfv5Manager.update(ctx.getImageData(0, 0, _width, _height));

    //et doDrawFaceDetection = !_brfv5Config.enableFaceTracking;

    if (_brfv5Config.enableFaceTracking) {
      const sizeFactor = Math.min(_width, _height) / 480.0;
      const faces = _brfv5Manager.getFaces();

      for (let i = 0; i < faces.length; i++) {
        const face = faces[i];

        if (face.state === brfv5.BRFv5State.FACE_TRACKING) {
          setPoseTargets(face);
          setHudText("brfv5 face tracking");
        }
        if (face.state === brfv5.BRFv5State.RESET) {
          setHudText("brfv5 face reset");
        }
        if (face.state === brfv5.BRFv5State.FACE_DETECTION) {
          setHudText("brfv5 looking for face");
        }
      }
    }

    if (mode == "brfv5") animID.current = requestAnimationFrame(trackFaces);
    else {
      cancelAnimationFrame(animID.current);
      setHudText("");
    }
  });

  // Calculate values sent to Avatar
  const setPoseTargets = useCallback((faceData) => {
    const landmarks = faceData.landmarks;

    // Face - mouth open estimation
    const lipsGap =
      ((landmarks[FACE.LIP_LOWER].y - landmarks[FACE.LIP_UPPER].y - 1) /
        faceData.scale) *
      2.5;

    const mouthOpennessNorm = Clamp(lipsGap, 0, 1);
    poseTargets.jawRotation = mouthOpennessNorm;

    // console.log(
    //   lipsGap.toFixed(2) +
    //     " | " +
    //     mouthOpennessNorm.toFixed(2) +
    //     " | " +
    //     faceData.scale.toFixed(2)
    // );

    const nose = new THREE.Vector2(
      landmarks[FACE.NOSE].x,
      landmarks[FACE.NOSE].y
    );
    const noseTop = new THREE.Vector2(
      landmarks[FACE.NOSE_TOP].x,
      landmarks[FACE.NOSE_TOP].y
    );
    const lEye = new THREE.Vector2(
      landmarks[FACE.EYE_L].x,
      landmarks[FACE.EYE_L].y
    );
    const lBrow = new THREE.Vector2(
      landmarks[FACE.BROW_L].x,
      landmarks[FACE.BROW_L].y
    );
    const rEye = new THREE.Vector2(
      landmarks[FACE.EYE_R].x,
      landmarks[FACE.EYE_R].y
    );
    const rBrow = new THREE.Vector2(
      landmarks[FACE.BROW_R].x,
      landmarks[FACE.BROW_R].y
    );
    const lBrowDistance = lBrow.distanceTo(nose);
    const rBrowDistance = rBrow.distanceTo(nose);
    const noseLength = noseTop.distanceTo(nose);
    const lBrowNorm = (lBrowDistance / noseLength - 2.5) * 1.5;
    const rBrowNorm = (rBrowDistance / noseLength - 2.5) * 1.5;

    // Avatar - set pose targets
    poseTargets.rotationZ = THREE.MathUtils.degToRad(-faceData.rotationZ);
    poseTargets.rotationY = THREE.MathUtils.degToRad(-faceData.rotationY);
    poseTargets.lBrow =
      lBrowNorm -
      Math.abs(THREE.MathUtils.degToRad(-faceData.rotationY) * 1.25);
    poseTargets.rBrow =
      rBrowNorm -
      Math.abs(THREE.MathUtils.degToRad(-faceData.rotationY) * 1.25);
    poseTargets.lerpOverride = SETTINGS.POSE_LERP_SPEED;
  });

  return (
    <>
      {showHud && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            fontSize: "25px",
            padding: "10px 40px",
          }}
        >
          {hudText}
        </div>
      )}
    </>
  );
}
